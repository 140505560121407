import axios from '@/config/axios';
import {
    IGetBrandPosRes,
    IGetRestaurantPosBranchRes,
    IGetRestaurantPosPaymentRes,
    IGetRestaurantPosRes,
    IRestaurant,
    IRestaurantPosPayment,
    IRestaurantTables,
    ITinyRestaurant,
} from '@/views/OrdersTableView/types';
import { SingleFlight } from '@/services/utils/single_flight';
import { ICreateQrGroup } from '@/views/QRCode/qr-group-editor';
import { AxiosResponse } from 'axios';
import { IQRGroupList, IQrRow } from '@/views/QRCode/types';
import { uniq } from 'lodash';
import {
    IGetQrPasscodeResponse,
    IGetSyncTableResponse,
    IQrDynamicPasscodePayload,
    IQrDynamicPasscodeResponse,
    ISetQrPasscodePayload,
    ISetQrPasscodeResponse,
    ISyncTable,
} from './interface/index';
import { transformError } from '.';

const endpoints = {
    RESTAURANT: 'vendor/restaurant',
    RESTAURANT_SEARCH: 'vendor/restaurant/search',
    RESTAURANT_GET: 'vendor/restaurant/:restaurant_id',
    RESTAURANT_EDIT: 'vendor/restaurant/:restaurant_id',
    RESTAURANT_EDIT_ORDER_CONFIG: 'vendor/restaurant/:restaurant_id/order_config',
    RESTAURANT_POS_INTEGRATED: 'vendor/restaurant/:restaurant_id/pos/:vendor',
    RESTAURANT_POS_UPDATE: 'vendor/restaurant/:restaurant_id/pos/:vendor',
    RESTAURANT_POS_BRANCH_GET: 'vendor/restaurant/:restaurant_id/pos/:vendor/branch',
    RESTAURANT_POS_BRANCH_UPDATE: 'vendor/restaurant/:restaurant_id/pos/:vendor/branch',
    RESTAURANT_POS_PAYMENT_GET: 'vendor/restaurant/:restaurant_id/pos/:vendor/payment',
    RESTAURANT_POS_PAYMENT_CREATE: 'vendor/restaurant/:restaurant_id/pos/:vendor/payment',
    RESTAURANT_POS_PAYMENT_UPDATE: 'vendor/restaurant/:restaurant_id/pos/:vendor/payment',
    BRAND_POS_INTEGRATED: 'vendor/brand/:brand_id/pos/:vendor',
    BRAND_POS_UPDATE: 'vendor/brand/:brand_id/pos/:vendor',
    QRS: 'vendor/restaurant/qr/:restaurant_id',
    QRS_GROUP_CREATE: 'vendor/qr-group/restaurant/:restaurant_id/create',
    QRS_GROUPS_GET: 'vendor/qr-group/restaurant/:restaurant_id/list',
    QRS_GROUP_GET: 'vendor/qr-group/restaurant/:restaurant_id/:group_id',
    QRS_GROUP_UPDATE: 'vendor/qr-group/restaurant/:restaurant_id/:group_id',
    QRS_GROUP_DELETE: 'vendor/qr-group/restaurant/:restaurant_id/:group_id',
    VPOS_ORDER: '/vendor/restaurant/vpos/:restaurant_id',
    VPOS_ORDER_DELETE: '/vendor/restaurant/vpos/:restaurant_id/order/:order_id',
    GET_QR_PASSCODE: '/vendor/qsr/qr/:restaurant_id/passcode',
    SET_QR_PASSCODE: '/vendor/qsr/qr/:restaurant_id/passcode',
    GET_QR_DYNAMIC_PASSCODE: '/vendor/qsr/qr/:restaurant_id/dynamic-passcode/get',
    SET_QR_DYNAMIC_PASSCODE: '/vendor/qsr/qr/:restaurant_id/dynamic-passcode/set',
    GET_TABLES: '/vendor/qsr/qr/:restaurant_id/vendor/foodics',
    SYNC_TABLES: '/vendor/qsr/qr/:restaurant_id/sync',
};

interface VPOSData {
    tableId: string;
    amount: string;
    name?: string;
    email?: string;
    mobile?: string;
    orderId?: string;
}

export interface IQrResponse {
    rows: IRestaurantTables[];
    count?: number;
}

class RestaurantService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new RestaurantService();
        }

        return this.instance;
    }

    private static instance: RestaurantService;

    private qrSingleFlight = new SingleFlight<IRestaurantTables>({ many: this.getQrsPr }, { useRequestCache: true });

    private qrGroupSingleFlight = new SingleFlight<IQrRow>({ many: this.getQrGroupsPr }, { useRequestCache: true });

    private locations: Record<string, string[]> = {};

    public getLocations(id: string): Promise<string[]> {
        if (this.locations[id]?.length) {
            return Promise.resolve(this.locations[id]);
        }

        return this.getQrs(id).then((res) => {
            const locations = uniq(
                res.rows.reduce<string[]>((a, c) => {
                    if (c.params.f2 === '_' || !c.params.f2) {
                        return a;
                    }
                    a.push(c.params.f2);
                    return a;
                }, []),
            );

            this.locations[id] = locations;
            return locations;
        });
    }

    public getRestaurants(params: { search: string }, signal?: any): Promise<IRestaurant[]> {
        return axios
            .get(endpoints.RESTAURANT, {
                signal,
                params: {
                    ...params,
                },
            })
            .then((res) => {
                return res.data?.data?.restaurantDetails || [];
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public searchRestaurants(params: { search: string }, signal?: AbortSignal): Promise<ITinyRestaurant[]> {
        return axios
            .get(endpoints.RESTAURANT_SEARCH, {
                params: {
                    ...params,
                },
                signal,
            })
            .then((res) => {
                return res.data?.data?.list || [];
            })
            .catch((err) => {
                if (err.message === 'canceled') {
                    console.log('Request canceled:', err.message);
                } else {
                    throw transformError(err);
                }
            });
    }

    public getRestaurantList(params: any): Promise<IRestaurant[]> {
        return axios
            .get('vendor/restaurant', {
                params,
            })
            .then((res) => {
                return res.data?.data?.restaurantDetails || [];
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getRestaurant(id: string): Promise<IRestaurant> {
        return axios
            .get(endpoints.RESTAURANT_GET.replace(':restaurant_id', id))
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public editRestaurant(id: string, data: any) {
        return axios
            .put<any>(endpoints.RESTAURANT_EDIT.replace(':restaurant_id', id), data)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public editRestaurantOrderConfig(id: string, data: { orderConfig: any }): Promise<IRestaurant> {
        return axios
            .put<any>(endpoints.RESTAURANT_EDIT_ORDER_CONFIG.replace(':restaurant_id', id), data)
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getRestaurantPosIntegrated(id: string, vendor: string): Promise<IGetRestaurantPosRes> {
        const query = endpoints.RESTAURANT_POS_INTEGRATED.replace(':restaurant_id', id).replace(':vendor', vendor);
        return axios
            .get<any>(query)
            .then((res) => res.data?.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public updateRestaurantPos(id: string, vendor: string, params: any) {
        const query = endpoints.RESTAURANT_POS_UPDATE.replace(':restaurant_id', id).replace(':vendor', vendor);
        return axios
            .put<any>(query, {
                params: {
                    ...params,
                },
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getBrandPosIntegrated(id: string, vendor: string): Promise<IGetBrandPosRes> {
        const query = endpoints.BRAND_POS_INTEGRATED.replace(':brand_id', id).replace(':vendor', vendor);
        return axios
            .get<any>(query)
            .then((res) => res.data?.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public updateBrandPos(id: string, vendor: string, params: any) {
        const query = endpoints.BRAND_POS_UPDATE.replace(':brand_id', id).replace(':vendor', vendor);
        return axios
            .put<any>(query, {
                params: {
                    ...params,
                },
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getRestaurantPosBranch(id: string, vendor: string): Promise<IGetRestaurantPosBranchRes> {
        const query = endpoints.RESTAURANT_POS_BRANCH_GET.replace(':restaurant_id', id).replace(':vendor', vendor);
        return axios
            .get<any>(query)
            .then((res) => res.data?.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public updateRestaurantPosBranch(id: string, vendor: string, params: { branchId: string }) {
        const query = endpoints.RESTAURANT_POS_BRANCH_UPDATE.replace(':restaurant_id', id).replace(':vendor', vendor);
        return axios
            .put<any>(query, {
                ...params,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getRestaurantPosPayment(id: string, vendor: string): Promise<IGetRestaurantPosPaymentRes> {
        const query = endpoints.RESTAURANT_POS_PAYMENT_GET.replace(':restaurant_id', id).replace(':vendor', vendor);
        return axios
            .get<any>(query)
            .then((res) => res.data?.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public createRestaurantPosPayment(id: string, vendor: string): Promise<IRestaurantPosPayment> {
        const query = endpoints.RESTAURANT_POS_PAYMENT_CREATE.replace(':restaurant_id', id).replace(':vendor', vendor);
        return axios
            .post<any>(query)
            .then((res) => res.data?.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public updateRestaurantPosPayment(id: string, vendor: string, params: { paymentId: string }) {
        const query = endpoints.RESTAURANT_POS_PAYMENT_UPDATE.replace(':restaurant_id', id).replace(':vendor', vendor);
        return axios
            .put<any>(query, {
                ...params,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public clearQrsCache() {
        return this.qrSingleFlight.clearCache();
    }

    public getQrs(restaurantId: string): Promise<IQrResponse> {
        return this.qrSingleFlight.getMany(restaurantId);
    }

    public vposOrder(id: string, data: VPOSData) {
        return axios
            .post<any>(endpoints.VPOS_ORDER.replace(':restaurant_id', id), data)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteVposOrder(id: string, orderId: string) {
        return axios
            .delete<any>(endpoints.VPOS_ORDER_DELETE.replace(':restaurant_id', id).replace(':order_id', orderId))
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    private getQrsPr(id: string): Promise<IQrResponse> {
        return axios
            .get<any>(endpoints.QRS.replace(':restaurant_id', id))
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public createQrGroup(restaurantId: string, data: ICreateQrGroup) {
        return axios
            .post<any>(endpoints.QRS_GROUP_CREATE.replace(':restaurant_id', restaurantId), data)
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getQrGroups(restaurantId: string) {
        return this.qrGroupSingleFlight.getMany(restaurantId);
    }

    private getQrGroupsPr(restaurantId: string) {
        return axios
            .get<AxiosResponse<IQRGroupList>>(endpoints.QRS_GROUPS_GET.replace(':restaurant_id', restaurantId))
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getQrGroup(restaurantId: string, groupId: string) {
        return axios
            .get<any>(endpoints.QRS_GROUP_GET.replace(':restaurant_id', restaurantId).replace(':group_id', groupId))
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public updateQrGroup(restaurantId: string, groupId: string, data: ICreateQrGroup) {
        return axios
            .put<any>(
                endpoints.QRS_GROUP_UPDATE.replace(':restaurant_id', restaurantId).replace(':group_id', groupId),
                data,
            )
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteQrGroup(restaurantId: string, groupId: string) {
        return axios
            .delete<any>(
                endpoints.QRS_GROUP_DELETE.replace(':restaurant_id', restaurantId).replace(':group_id', groupId),
            )
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getQrPasscode(restaurantId: string): Promise<IGetQrPasscodeResponse> {
        return axios
            .get<any>(endpoints.GET_QR_PASSCODE.replace(':restaurant_id', restaurantId))
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public setQrPasscode(restaurantId: string, payload: ISetQrPasscodePayload): Promise<ISetQrPasscodeResponse> {
        return axios
            .post<any>(endpoints.SET_QR_PASSCODE.replace(':restaurant_id', restaurantId), payload)
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getQrDynamicPasscode(
        restaurantId: string,
        payload: IQrDynamicPasscodePayload,
    ): Promise<IQrDynamicPasscodeResponse> {
        return axios
            .post<any>(endpoints.GET_QR_DYNAMIC_PASSCODE.replace(':restaurant_id', restaurantId), payload)
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public setQrDynamicPasscode(
        restaurantId: string,
        payload: IQrDynamicPasscodePayload,
    ): Promise<IQrDynamicPasscodeResponse> {
        return axios
            .post<any>(endpoints.SET_QR_DYNAMIC_PASSCODE.replace(':restaurant_id', restaurantId), payload)
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getTables(restaurantId: string, vendor: string): Promise<IGetSyncTableResponse[]> {
        return axios
            .get<any>(endpoints.GET_TABLES.replace(':restaurant_id', restaurantId).replace(':vendor', vendor))
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public syncTable(restaurantId: string, payload: ISyncTable[]) {
        return axios
            .post<any>(endpoints.SYNC_TABLES.replace(':restaurant_id', restaurantId), payload)
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }
}

export default RestaurantService;
